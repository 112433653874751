.read-more-less--more,
.read-more-less--less {
  color: #008ED9;
  font-weight: 600;
}

.card {
  padding: 0 15px;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
}

.react-multiple-carousel__arrow {
  margin-top: -150px
}