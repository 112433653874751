.choose::-webkit-file-upload-button {
    
    display: inline-block;
    background: "#848484";
    border: none;
    font-size: 25px;
    font-weight: 600;
    color:black;
    padding: 15px 15px;
    font-weight: 700;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 10pt;
  }